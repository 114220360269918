import React from 'react'


const DevApproach = () => {


  return (
    <div className='pt-100'>
    <section className="devapproach mb-0">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
            <h3 className="heading-h2">Decentralized Software <span className="bluecolor">Development Process</span>
            </h3>
            <p className="pharagraph head">Our team of blockchain developers empowers your DeFi platform development with innovative ideas. Explore creativity and a range of innovations with our DeFi development services.
            </p>
          </div>
        </div>
        <div className="row table-content orderflex">
          <div className="col-lg-6 col-md-12  text-center" >
            <img width="384px" height="264px" src="https://coinsclone.mo.cloudinary.net/images/defidevelopment/project-analysis.svg" alt="project analysis image1" />
          </div>
          <div className="col-lg-6 col-md-12 padl60">
            <h5 >Requirement Gathering</h5>
            <p  className="pharagraph">In the initial stage, we gather client requirements and analyze them with the current crypto market trends. After a clear analysis, we will provide an appropriate solution for building your DeFi platform.
            </p>
            <br />
            <h5 >Planning </h5>
            <p  className="pharagraph">Our professional conceptualizes the DeFi platform development idea and frames the roadmap. Also, we gather essential resources, tools, and utilities. This helps you meet your business needs with unique service offerings.
            </p>
          </div>
        </div>
        <div className="row table-content orderflex">
          <div className="col-lg-6 col-md-12 padr40 order2">
            <h5 >Designing</h5>
            <p  className="pharagraph">In this stage, our experts will complete the UI/UX design ensuring comfort and convenience to users. Once we get approval from clients, the design team will move the design to final development.
            </p>
            <br />
            <h5 >Development</h5>
            <p  className="pharagraph">Then comes the development phase where backend, frontend, and technical side website building occurs. Our developer team is well-versed in building an effective website brick by brick.
            </p>
          </div>
          <div className="col-lg-6 col-md-12  text-center">
            <img width="384px" height="264px" src="https://coinsclone.mo.cloudinary.net/images/defidevelopment/designing-development.svg" alt="Designing and development image1" /></div>
        </div>
        <div className="row table-content orderflex mb-0">
          <div className="col-lg-6 col-md-12  text-center" >
            <img width="384px" height="264px" src="https://coinsclone.mo.cloudinary.net/images/defidevelopment/testing-deployment.svg" alt="Testing and deployment image1" />
          </div>
          <div className="col-lg-6 col-md-12 padl60">
            <h5 >Testing</h5>
            <p  className="pharagraph">Once the website creation is over, our developer team conducts multiple tiers of testing to ensure the quality. Our team integrates advanced-level security protocols to protect the Defi platform from hacks and data thefts.
            </p>
            <br />
            <h5 >Deployment</h5>
            <p  className="pharagraph mb-0">We deploy the Defi platform on the blockchain network for beta testing. Our team also provides prolonged support and maintenance for the website to ensure productivity.
            </p>
          </div>
        </div>
      </div>
    </section>
    </div>
  )
}

export default DevApproach