import React from 'react'




const HowOur = () => {



  return (
    <section className="how-our mb-0">
      <div className="container">
        <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
          <h2 className="heading-h2"><span className="heading-h3">How Does our
          </span>
          DeFi Development Work?
          </h2>
        </div>
        <svg xmlns="http://www.w3.org/2000/svg" width="989.389" height="1083.733" viewBox="0 0 989.389 1083.733">
        <g id="Flow" transform="translate(-427.417 -5088.096)">
          <g id="Ellipse_1376" data-name="Ellipse 1376" transform="translate(551 5088.096)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
            <circle cx={108} cy={108} r={108} stroke="none" />
            <circle cx={108} cy={108} r={107} fill="none" />
          </g>
          <g id="Ellipse_196" data-name="Ellipse 196" transform="translate(1094 5132.096)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
            <circle cx={91} cy={91} r={91} stroke="none" />
            <circle cx={91} cy={91} r={90} fill="none" />
          </g>
          <g id="Image" transform="translate(1040.622 5124.194)">
            <path id="Path_2183" data-name="Path 2183" d="M229.764,693.214a2.373,2.373,0,0,1-1.72-4.113l.163-.646a6.376,6.376,0,0,0-11.819-.111c-2.812,6.583-6.452,13.4-4.642,20.649-11.957-25.341-7.89-56.863,9.78-78.535a19.451,19.451,0,0,0,9.874-13.021c-2.418.819-5.432-1.147-3.614-3.693.788-.973,1.584-1.938,2.371-2.912-9.179-9.779-20.123,5.591-10.016,18.792a76.08,76.08,0,0,0-8.545,13.36,31.991,31.991,0,0,0-1.91-14.859c-1.828-4.409-5.255-8.124-8.272-11.937a6.567,6.567,0,0,0-11.713,3.393c3.385,1.113,3.826,6-.047,6.673A32.745,32.745,0,0,0,207.7,652.105a76.382,76.382,0,0,0-4.174,31.926c-1.742-12.643-15.313-15.711-24.8-20.736a3.7,3.7,0,0,0-5.638,3.742,21.35,21.35,0,0,1,3.741,1.973,3.226,3.226,0,0,1-1.5,5.888c4.781,11.833,17.143,19.78,29.839,19.732a78.521,78.521,0,0,0,5.276,15.4H229.29c.068-.21.129-.426.189-.635a21.413,21.413,0,0,1-5.214-.311c4.615-5.463,8.183-8.251,5.5-15.879Z" transform="translate(-163.23 -399.795)" fill="#f2f2f2" />
            <path id="Path_2184" data-name="Path 2184" d="M253.4,592.178h7.77l3.7-29.969H253.4Z" transform="translate(-86.977 -296.606)" fill="#ffb6b6" />
            <path id="Path_2185" data-name="Path 2185" d="M420.043,739.479V734.21l-2.043.125-8.312.493-1.924.119-1.471,17.641-.079.945h5.517l.177-.94.8-4.269,2.069,4.269.453.94h14.626a3.3,3.3,0,0,0,3.284-3.29C433.756,747.357,421.593,741.125,420.043,739.479Z" transform="translate(-243.266 -443.206)" fill="#2f2e41" />
            <path id="Path_2186" data-name="Path 2186" d="M114.8,581.339l6.757,3.831,18-24.241-9.974-5.659Z" transform="translate(-39.404 -294.225)" fill="#ffb6b6" />
            <path id="Path_2187" data-name="Path 2187" d="M276.187,731.764l2.594-4.577-1.826-.9-7.481-3.671-1.735-.854-8.518,12.472-2,2.929,2.266,1.287,2.53,1.438,3.428-4.047-.381,5.773,11.947,6.785h.007l.768.434a3.289,3.289,0,0,0,4.481-1.241C284.219,745.384,276.72,733.97,276.187,731.764Z" transform="translate(-192.126 -438.933)" fill="#2f2e41" />
            <path id="Path_2188" data-name="Path 2188" d="M446.12,362.682a6.928,6.928,0,0,0-5.546,8.976l-11.889,10.291,4.491,8.751,16.585-14.768a6.918,6.918,0,0,0-3.642-13.248Z" transform="translate(-250.98 -315.656)" fill="#ffb6b6" />
            <path id="Path_2189" data-name="Path 2189" d="M408.818,396.194l-4.466-8.753-.46-.9c-4.781,1.681-14.173,7.172-18.455,9.974-1.57-2.187-10.3-11.7-17.844-15.355,0-.007-.007,0-.007,0q-.778-.374-1.537-.67a10.615,10.615,0,0,0-3.671-.762,5.652,5.652,0,0,0-1.649.236,4.635,4.635,0,0,0-1.55.8c-1.229.946-2.161,2.594-2.686,5.142a10.059,10.059,0,0,0,.466,3.573c2.1,6.968,10.4,14.751,13.339,19.769.289.775,3.172,8.407,7.724,11.113a7.34,7.34,0,0,0,.742.394c.283.04.564.072.86.085h.007a12.084,12.084,0,0,0,1.386.019c.236-.006.479-.026.723-.046.053-.007.1-.007.151-.013a21.9,21.9,0,0,0,4.46-.972c.164-.053.328-.1.492-.164a36.687,36.687,0,0,0,3.658-1.471c.69-.315,1.373-.657,2.056-1.012.828-.434,1.655-.893,2.463-1.373.7-.4,1.379-.821,2.049-1.255h0c.158-.092.315-.2.473-.309h.007c5.99-3.894,10.876-8.42,11.815-10.292a15.21,15.21,0,0,0,1.694-3.356Z" transform="translate(-226.196 -321.534)" fill="#2f2e41" />
            <path id="Path_2190" data-name="Path 2190" d="M391.58,451.693s16.465,18.526,12.327,22.665-10.58,5.226-10.58,5.226l-7.874-.607Z" transform="translate(-236.14 -346.235)" fill="#2f2e41" />
            <path id="Path_2191" data-name="Path 2191" d="M364.027,376.021c21.236,12.425,28.377,24.3,18.556,48.181Z" transform="translate(-228.787 -320.261)" fill="#2f2e41" />
            <path id="Path_2192" data-name="Path 2192" d="M318.893,505.047c-1.723-.8-6,63.85-6.352,65.474L288,616.254l34.929,15.657,22.965-79.745,3.831,29.344-5.1,46.571,22.578,5.46,15.616-1.115L371.969,499.85l-20.582-37.318Z" transform="translate(-202.691 -349.955)" fill="#2f2e41" />
            <path id="Path_2193" data-name="Path 2193" d="M351.648,378.81c28.839,21.466,21.794,21.851,22.029,54.986l13.74,36.717s-48.438,18.759-53.4,6.343S351.648,378.81,351.648,378.81Z" transform="translate(-218.239 -321.219)" fill="#63daff" />
            <path id="Path_2194" data-name="Path 2194" d="M373.514,417.2c-.059-.21-.132-.453-.21-.709h0c-.125-.414-.27-.867-.44-1.386-.1-.328-.217-.683-.341-1.057-.079-.236-.158-.479-.243-.736-2.313-6.981-6.975-20-12.636-34.388v-.007q-1.025-2.591-2.089-5.234c-.066-.158-.125-.322-.191-.479-.283-.7-.572-1.412-.86-2.121l-8.294-8.3-4.473-2.916-9.851,9.1s.006.158.02.466c.138,2.8.815,17.949.9,35.849.02,4.512.006,9.2-.072,13.91v.125c-.02,1.234,0,2.194.059,2.968a12.938,12.938,0,0,0,.191,1.642c.368,2.024,1.044,2.5,1.406,6.706,0,0-7.75,9.989-3.252,18.541a6.162,6.162,0,0,0,1.077.066c1.59.026,5.294-.072,15.48-.079,1.766-.013,3.724-.013,5.9-.007,14.245,0,6.568-8.24,14.179-15.749.164-.164.328-.322.506-.479a17.236,17.236,0,0,1,1.708-1.36l1.274-3.954.407-1.268.007-.164.04-1.753.085-3.494.059-2.325A8.625,8.625,0,0,0,373.514,417.2Z" transform="translate(-217.701 -314.715)" fill="#2f2e41" />
            <path id="Path_2195" data-name="Path 2195" d="M182.076,274.171l-2.262,14.12,15.313,6.317Z" transform="translate(-61.719 -197.739)" opacity="0.2" />
            <path id="Path_2196" data-name="Path 2196" d="M364.226,311.956c17.13,9.749,3.067,35.472-14.383,26.309C332.709,328.518,346.774,302.8,364.226,311.956Z" transform="translate(-221.249 -297.606)" fill="#ffb6b6" />
            <path id="Path_2197" data-name="Path 2197" d="M326.6,448.837c9.085,10.706,24.566,12.252,36.324,5.067Z" transform="translate(-215.94 -345.254)" opacity="0.2" />
            <path id="Path_2198" data-name="Path 2198" d="M369.739,313.56c-1.2,1.561-3.709.829-8.62,1.944a20.472,20.472,0,0,0-.572-4.943,13.913,13.913,0,0,1-2.346,5.565c-4.264.473.963,13.429-1.221,22.955-2.814-20.093-8.731-14.831-5.742-10.845,1.914,2.552.638,10.208-5.1,6.38-9.473,4.5-22.967-17.863-8.012-28.352-.267-5.669,6.041-6.565,9.926-4.822C357.477,294.416,368.248,303.414,369.739,313.56Z" transform="translate(-217.602 -293.805)" fill="#2f2e41" />
            <path id="Path_2199" data-name="Path 2199" d="M403.864,432.26c4.869-.1,31.025-.848,35.315-.663.243-.849,8.373,9.571,8.571,9.635a1.188,1.188,0,0,1-.4,1.91c-.478.289-42.643-.489-43.155-.388a1.173,1.173,0,0,1-1.047-.682l-.282-.631c-.03-1.08-.383-6.6-.066-7.543a1.168,1.168,0,0,1,1.061-1.638Z" transform="translate(-242.051 -339.32)" fill="#3f3d56" />
            <path id="Path_2200" data-name="Path 2200" d="M402.971,446.61c.247.6.564,1.342,1.328,1.315l42.71.479a1.178,1.178,0,0,0,1.028-1.73Z" transform="translate(-242.153 -344.49)" opacity="0.17" />
            <path id="Path_2201" data-name="Path 2201" d="M457.936,395.857c.46-.8,11.878-57.295,12.831-57.767l7.8-9.537.7-.524c.969-.532,3.293-.173,3.084,1.307h0c-1.831,8.781-13.612,58.792-13.87,66.589l-1.418,9.933a1.223,1.223,0,0,1-1.511,1,2.353,2.353,0,0,1-1.89-1.365C463.55,405.148,457.4,396.061,457.936,395.857Z" transform="translate(-261.008 -303.709)" fill="#3f3d56" />
            <path id="Path_2202" data-name="Path 2202" d="M481.572,328.563,466.661,405.51c.5,1.262,2.832,2.147,3.375.491.009,0,15.3-76.607,15.312-76.653C485.512,327.5,482.465,327.445,481.572,328.563Z" transform="translate(-264.014 -303.717)" opacity="0.17" />
            <path id="Path_2203" data-name="Path 2203" d="M322.174,200.416,314.6,210.968l-8.5,40.371,3.951,3.447Z" transform="translate(-105.066 -172.424)" fill="#6c63ff" />
            <path id="Path_2204" data-name="Path 2204" d="M410.2,435.567c-.158-1.221-.46-2.345-1.347-2.259-1.307-.007-1.025,1.773-.709,3.271a8.233,8.233,0,0,1,.3,1.813l2.1-.085a17.278,17.278,0,0,1-.342-2.739Z" transform="translate(-243.837 -339.922)" fill="#2f2e41" />
            <path id="Path_2205" data-name="Path 2205" d="M429.09,435.018c-.013-.348.006-.8-.04-1.215-.072-.7-.309-1.3-1.1-1.255a1.053,1.053,0,0,0-.427.085c-.847.381-.5,1.924-.513,2.674l.683-.026,1.412-.053C429.1,435.162,429.09,435.09,429.09,435.018Z" transform="translate(-250.383 -339.662)" fill="#2f2e41" />
            <path id="Path_2206" data-name="Path 2206" d="M435.46,434.758c-.033-.919.132-2.542-1.143-2.47-1.379.026-.926,1.9-.94,2.765l.677-.026,1.419-.057C435.467,434.9,435.46,434.83,435.46,434.758Z" transform="translate(-252.57 -339.574)" fill="#2f2e41" />
            <path id="Path_2207" data-name="Path 2207" d="M441.83,434.508c-.033-.926.132-2.548-1.143-2.47-1.379.026-.926,1.9-.94,2.758l.683-.026,1.412-.053C441.837,434.652,441.83,434.58,441.83,434.508Z" transform="translate(-254.756 -339.488)" fill="#2f2e41" />
            <path id="Path_2208" data-name="Path 2208" d="M448.2,434.252c-.033-.919.132-2.542-1.143-2.47-1.379.033-.926,1.9-.94,2.765l.677-.026,1.419-.059C448.207,434.4,448.2,434.324,448.2,434.252Z" transform="translate(-256.942 -339.4)" fill="#2f2e41" />
            <path id="Path_2209" data-name="Path 2209" d="M454.57,434c-.026-.828.1-2.207-.781-2.436h-.008a.989.989,0,0,0-.355-.033.959.959,0,0,0-.289.039h-.006c-1.012.283-.63,1.931-.644,2.719l.683-.026,1.412-.053C454.576,434.142,454.57,434.07,454.57,434Z" transform="translate(-259.129 -339.312)" fill="#2f2e41" />
            <path id="Path_2210" data-name="Path 2210" d="M417.185,432.1v-.006a2.041,2.041,0,0,0-.053-.289c-.1-.558-.374-.992-1.051-.953-.664.013-.9.447-.972,1a8.761,8.761,0,0,0,.026,1.5c.007.1.007.184.007.262l2.1-.085C417.2,433.16,417.237,432.609,417.185,432.1Z" transform="translate(-246.313 -339.079)" fill="#2f2e41" />
            <path id="Path_2211" data-name="Path 2211" d="M423.525,431.611c-.1-.585-.374-1.057-1.07-1.012-.69.013-.919.486-.979,1.064a15.268,15.268,0,0,0,.04,1.694l2.1-.085C423.571,432.851,423.623,432.174,423.525,431.611Z" transform="translate(-248.503 -338.993)" fill="#2f2e41" />
            <path id="Path_2212" data-name="Path 2212" d="M429.894,431.422c-.092-.617-.348-1.123-1.077-1.084-.716.013-.932.519-.985,1.13a2.793,2.793,0,0,0-.013.407c.007.453.066.913.059,1.229l1.471-.059.624-.026C429.934,432.611,429.986,431.972,429.894,431.422Z" transform="translate(-250.681 -338.905)" fill="#2f2e41" />
            <path id="Path_2213" data-name="Path 2213" d="M416.015,436.589c-1.379.026-.932,1.9-.94,2.758l2.1-.079C417.093,438.408,417.4,436.5,416.015,436.589Z" transform="translate(-246.286 -341.049)" fill="#2f2e41" />
            <path id="Path_2214" data-name="Path 2214" d="M422.386,436.329c-1.379.026-.932,1.9-.94,2.765l2.1-.085C423.463,438.155,423.765,436.25,422.386,436.329Z" transform="translate(-248.473 -340.961)" fill="#2f2e41" />
            <path id="Path_2215" data-name="Path 2215" d="M429.367,436.228a.923.923,0,0,0-.611-.151.867.867,0,0,0-.788.413c-.374.6-.145,1.735-.151,2.345l2.1-.079C429.846,438.06,430.036,436.67,429.367,436.228Z" transform="translate(-250.66 -340.873)" fill="#2f2e41" />
            <path id="Path_2216" data-name="Path 2216" d="M435.737,435.968a.913.913,0,0,0-.611-.151.867.867,0,0,0-.8.421c-.361.611-.138,1.735-.145,2.345l2.1-.085C436.217,437.807,436.407,436.421,435.737,435.968Z" transform="translate(-252.847 -340.784)" fill="#2f2e41" />
            <path id="Path_2217" data-name="Path 2217" d="M442.107,435.718a.923.923,0,0,0-.611-.151.867.867,0,0,0-.788.413c-.374.6-.145,1.735-.151,2.345l2.1-.079C442.586,437.55,442.776,436.158,442.107,435.718Z" transform="translate(-255.032 -340.698)" fill="#2f2e41" />
            <path id="Path_2218" data-name="Path 2218" d="M448.481,435.458a.912.912,0,0,0-.611-.151.862.862,0,0,0-.8.421c-.361.611-.138,1.735-.145,2.345l2.1-.085C448.958,437.3,449.149,435.911,448.481,435.458Z" transform="translate(-257.22 -340.609)" fill="#2f2e41" />
            <path id="Path_2219" data-name="Path 2219" d="M454.848,435.208a.922.922,0,0,0-.611-.151.859.859,0,0,0-.788.413c-.368.611-.145,1.735-.151,2.345l2.1-.079C455.327,437.038,455.518,435.655,454.848,435.208Z" transform="translate(-259.407 -340.523)" fill="#2f2e41" />
            <path id="Path_2220" data-name="Path 2220" d="M304.074,297.769c-3.814,23.452-24.259-4.313-33.872,18.766-3.023,8.358,2.163,14.337,5.443,21.274.749,5.7-6.668,8.594-7.445,13.842-.217,12.032,19.2,11.3,24.621,3.7,5.575-7.946-1.084-18.608,2.269-27.137,4.71-11.842,23.867-11.93,25.535-25.707C317.394,297.908,308.7,292.637,304.074,297.769Z" transform="translate(-195.894 -292.677)" fill="#2f2e41" />
            <path id="Path_2221" data-name="Path 2221" d="M446.863,763.3c-.506,1.493-288.213,1.493-288.757,0C158.608,761.809,446.319,761.809,446.863,763.3Z" transform="translate(-158.106 -452.807)" fill="#ccc" />
            <path id="Path_2222" data-name="Path 2222" d="M344.007,298.5a3.41,3.41,0,0,0-.361.164h-.007c-.1.053-.21.1-.315.164l-.033.02c-.138-.132-.276-.262-.421-.394a1.517,1.517,0,0,0,.026.644l.02.079c.132.138.27.27.413.407.132-.1.256-.2.387-.3.079-.066.158-.132.243-.2.013-.171.033-.368.053-.585Zm3.954-7.3c-2.929-.67-3.875,3.159-4.157,5-1.064-6.7-5.493-4.591-3.96-.02a10.236,10.236,0,0,0,2.253,3.625c.138.158.289.315.447.473-.177.919.716.683,1.924-.164a16.1,16.1,0,0,0,2.463-2.161c2.41-2.532,4.285-5.915,1.033-6.762Zm.092,3.77a22.946,22.946,0,0,1-3.028,3.211q-.522.473-1.064.906c-.007.059-.007.112-.013.158a1.208,1.208,0,0,1-.092.184c-.368.664-.781.3-.94-.249-.013-.02-.033-.04-.046-.059a9.257,9.257,0,0,1-1.36-1.8c-2.713-4.7,1.655-7.9,1.438.88a.832.832,0,0,0-.079.256,1.519,1.519,0,0,0,.026.643l.02.079c.132.138.27.27.413.407.132-.1.256-.2.387-.3.079-.066.158-.132.243-.2.013-.171.033-.368.053-.585h0c.171-1.76.637-4.926,1.5-5.786,1.891-1.451,4.341.119,2.542,2.259ZM344,298.5a3.41,3.41,0,0,0-.361.164h-.007c-.1.053-.21.1-.315.164l-.033.02c-.138-.132-.276-.262-.421-.394a1.518,1.518,0,0,0,.026.644l.02.079c.132.138.27.27.413.407.132-.1.256-.2.387-.3.079-.066.158-.132.243-.2.013-.171.033-.368.053-.585Z" transform="translate(-220.376 -291.122)" fill="#2f2e41" />
            <path id="Path_2223" data-name="Path 2223" d="M457.172,388.453a3.614,3.614,0,0,0-3.614,3.614v34.942H384.272a3.614,3.614,0,0,0,0,7.228H453.56V635.2h7.228V392.062a3.614,3.614,0,0,0-3.614-3.614Z" transform="translate(-234.495 -324.527)" fill="#e6e6e6" />
            <path id="Path_2224" data-name="Path 2224" d="M390.1,441.527a6.928,6.928,0,0,0-10.546.319l-15.139-4.25-4.827,8.57,21.466,5.7a6.918,6.918,0,0,0,9.045-10.34Z" transform="translate(-227.262 -341.396)" fill="#ffb6b6" />
            <path id="Path_2225" data-name="Path 2225" d="M337.2,409.768c-1.1-.572-2.194-1.13-3.264-1.649-2.325-1.149-4.486-2.154-6.075-2.837a54.931,54.931,0,0,0,1.852-7.238c1.865-9.4,3.132-24.064-5.372-23.63a11.1,11.1,0,0,0-3.573.867c-6.626,3.686-8.9,18.337-11.868,24.386-.519.7-5.786,8.006-4.939,13.411,2.4,5.254,10.047,9.747,17.214,12.669l.007.007c1.379.565,2.747,1.07,4.048,1.518,1.485.506,2.9.926,4.164,1.255a17.774,17.774,0,0,0,5.458.821,15.385,15.385,0,0,0,3.737-.434l1.5-2.653.926-1.642,4.843-8.551.5-.88a72.263,72.263,0,0,0-9.155-5.421Z" transform="translate(-208.138 -319.706)" fill="#2f2e41" />
          </g>
          <g id="Group_25102" data-name="Group 25102" transform="translate(575.53 5184.711)">
            <g id="Group_25101" data-name="Group 25101" transform="translate(25.44 59.354)">
              <path id="Path_17988" data-name="Path 17988" d="M921.243,579.219,902.9,577.14a59.562,59.562,0,0,1-5.116-28.2c9.97,14.058,31.064,13.833,45.081,23.861a35.82,35.82,0,0,1,14.721,27.343l6.057,11.607A60.035,60.035,0,0,1,915.974,595.8a58,58,0,0,1-9.146-11.309C914.04,582.306,921.243,579.219,921.243,579.219Z" transform="translate(-897.664 -472.19)" fill="#e2e2e2" />
              <path id="Path_17989" data-name="Path 17989" d="M916.363,536.841,900.871,526.8a59.564,59.564,0,0,1,8-27.519c2.655,17.03,21.636,26.234,29.708,41.464a35.821,35.821,0,0,1,.98,31.035l.245,13.091a60.036,60.036,0,0,1-35.546-35.54,58,58,0,0,1-3.145-14.2C908.536,536.4,916.363,536.841,916.363,536.841Z" transform="translate(-878.819 -456.229)" fill="#e2e2e2" />
              <path id="Path_17992" data-name="Path 17992" d="M607.883,625.7l7.709.858,7.3-29.914-11.377-1.267Z" transform="translate(-474.016 -406.737)" fill="#a0616a" />
              <path id="Path_17993" data-name="Path 17993" d="M754.948,553.561c.24-.238.5-.47.757-.691a70.294,70.294,0,0,1,6.227-4.612c2.439-1.627,5.2-3.288,8.3-5q2.257-1.247,4.77-2.545,1.26-.651,2.6-1.311c.038-.022.077-.042.117-.06l.261-.137,18.708,15.449,1.329,1.045-3.447,4.249s.3,3.378.293,3.382l3.418,8.537.037.017,12.184,5.56,12.56,5.733,6.746,3.082a12.96,12.96,0,0,1,6.794,7.354,13.473,13.473,0,0,1-.444,10.405c-.151.329-.32.66-.5.979l-2.8,17.625a106.091,106.091,0,0,1-9.654,30.344l-4.282,16.5-.651-.327-9.684-4.851-2.558-1.278-.483-11.085c-.055-1.263-.063-2.533-.017-3.8a48.858,48.858,0,0,1,.595-6.194l5.552-34.569q-1.481-.6-2.916-1.21-6.665-2.788-12.62-5.639A161.339,161.339,0,0,1,764.11,581q-3.623-2.919-6.794-5.977-1.166-1.117-2.271-2.256c-.174-.174-.348-.348-.514-.523a13.116,13.116,0,0,1-3.255-12.128,13.383,13.383,0,0,1,3.673-6.544Z" transform="translate(-671.869 -469.063)" fill="#2f2e41" />
              <path id="Path_17994" data-name="Path 17994" d="M774.535,750.678l8.34.923,1.307.137-.852,4.956,9.7,9.473a3.145,3.145,0,0,1-2.724,5.346l-13.772-2.36L775,763.844l-1.767,4.743-5.187-.888,4.454-17.247Z" transform="translate(-640.041 -536.954)" fill="#2f2e41" />
              <path id="Path_17995" data-name="Path 17995" d="M791.9,477.966l-7.143-7.578,10.08-22.054,1.5-7.747a5.857,5.857,0,0,1,9.562-3.335h0a5.857,5.857,0,0,1,.761,8.106l-5.565,6.956Z" transform="translate(-665.354 -435.842)" fill="#a0616a" />
              <path id="Path_17996" data-name="Path 17996" d="M837.173,470.388l8.143,8.143-1.558,11.907a9.469,9.469,0,0,1-6.828,6.333h0a9.469,9.469,0,0,1-10.313-4.34l-18.309-31.4a9.669,9.669,0,0,1,3.117-13.139h0a9.669,9.669,0,0,1,13.845,3.9l11.179,23.823Z" transform="translate(-717.765 -439.233)" fill="#e6e6e6" />
              <path id="Path_17997" data-name="Path 17997" d="M772.642,646.862c.421.428.842.852,1.27,1.275q1.274,1.271,2.574,2.518c.436.41.871.833,1.307,1.243v.015l.029.012.008.007.725.375,1.8-2.655.831-1.22,1.176-1.733,2.153-3.169,11.335-16.679L785.688,621.6l-.8,1.558h0l-.4.773-7.435,14.4-1.517,2.945-.048.087-.912,1.758-.784,1.524-.878,1.7Z" transform="translate(-645.03 -495.538)" fill="#a0616a" />
              <path id="Path_17998" data-name="Path 17998" d="M802.468,650.338l7.077,4.511,1.111.708-2.956,4.067,4.511,12.786A3.145,3.145,0,0,1,807.406,676L796.1,667.8l.973-5.438-3.682,3.472-4.26-3.09,11.621-13.5Z" transform="translate(-672.756 -504.425)" fill="#2f2e41" />
            </g>
            <g id="Group_25100" data-name="Group 25100">
              <path id="Path_18019" data-name="Path 18019" d="M744.129,564.7c.7,1.132,1.423,2.256,2.148,3.371q2.019,3.114,4.148,6.138l.01.011a14.5,14.5,0,0,0,2.725,1.989,1.235,1.235,0,0,0,.129.077,10.046,10.046,0,0,0,2.293.92l.871.216c1.037.247,2.065.458,3.088.643q2.734.485,5.408.7c.137.015.288.026.436.036a83.622,83.622,0,0,0,21.988-1.753c3.692-.7,7.458-1.536,11.359-2.4,1.274-.288,2.554-.57,3.85-.853.1-.021.2-.046.3-.067,1.85-.41,3.731-.807,5.662-1.2,2.425-.5,1.183-.528,3.783-.984,0,0-6,6.1-7,8.735a14.218,14.218,0,0,0-.137,11.168l-5.763,11.312,20.035,3.4c11.041-21.634,19.861-35.882,15.923-44.272-.015-.391-.046-.784-.1-1.177a13.465,13.465,0,0,0-5.125-9.067,12.968,12.968,0,0,0-9.658-2.641l-3.932.509-30.488-1.481-1.2-4.878-.221-.082-7.041-2.574-24.3-8.907-.062-.021-1.6-.586-.19.309c-.056.093-.113.185-.164.273a47.64,47.64,0,0,0-3.839,8.913,139.916,139.916,0,0,0-4.034,15.4q-.07.339-.123.617l-.015.056c-.015.087-.026.137-.031.16a14.379,14.379,0,0,0,.862,8.006Z" transform="translate(-639.995 -407.057)" fill="#2f2e41" />
              <line id="Line_187" data-name="Line 187" x2="0.483" transform="translate(168.053 217.874)" fill="#2f2e41" />
              <path id="Path_18020" data-name="Path 18020" d="M821.847,435.259l.174-9.741,15.434-1.794,1.618,8.225,16.216,15.244c11.532,14.1,3.152,23.82,2.651,25.267l-1.426,17.77-.9,6.177,3.5,16.907L814.9,522.8c-1.416-11.686.161-22.546,9.391-31.287L819.631,477h0a29.807,29.807,0,0,1-4.338-12.5l-.168-1.517C811.01,450.14,813.456,445.816,821.847,435.259Z" transform="translate(-712.592 -372.591)" fill="#e6e6e6" />
              <path id="Path_18021" data-name="Path 18021" d="M845.931,386.249c-.011.252-.015.5-.015.751a17.254,17.254,0,0,0,22.6,16.376l3.168-1.034c.026-.011.057-.015.082-.026L877,400.61a3.584,3.584,0,0,0-.011-.663c7.793-3.33-2.98-14.274-5.662-22.493-.41-1.28,13.9-.324,13.22-1.419.149-.077.3-.154.436-.231a21.191,21.191,0,0,0-23.4-9.118c-.113.026-.231.062-.348.093-10.157.252-15.491,7.329-15.491,16.519a25.2,25.2,0,0,0,.188,2.95Z" transform="translate(-758.328 -354.03)" fill="#2f2e41" />
              <path id="Path_18022" data-name="Path 18022" d="M843.551,390.926a16.559,16.559,0,0,0,20.873,10.6q.71-.231,1.388-.523a16.551,16.551,0,1,0-22.26-10.078Z" transform="translate(-747.144 -355.079)" fill="#a0616a" />
              <path id="Path_18023" data-name="Path 18023" d="M861.938,401.078a.568.568,0,0,0,.082-.027l5.227-1.7c.014-.224,9.61,6.127,9.582,5.9-.149-1.717-10.959-10.091-12.578-12.039a10.017,10.017,0,0,1,4.4-15.907.805.805,0,0,0,.137-.048c1.011-.348,1.981-.713,2.891-1.072.449-1.866.684-1.248,1.758-2.83-.2,1.438,1.628,2.526,1.655,3.977.373-.17.726-.348,1.065-.523.149-.082.291-.156.436-.231a7.206,7.206,0,0,0,2.667-2.132c-2.613-8.028-12.608-14.455-20.636-11.828l-3.034.984a15.223,15.223,0,0,0-8.842,7.519,17.138,17.138,0,0,0-5.139,6.441C834.962,390.906,847.781,405.625,861.938,401.078Z" transform="translate(-748.571 -352.763)" fill="#2f2e41" />
              <circle id="Ellipse_1468" data-name="Ellipse 1468" cx="7.195" cy="7.195" r="7.195" transform="translate(84.222 11.608)" fill="#2f2e41" />
              <path id="Path_18024" data-name="Path 18024" d="M900.795,381.853a47.721,47.721,0,0,0,25.19,32.391q1.213.6,2.461,1.119a16.278,16.278,0,0,1,2.761-9.434,28.969,28.969,0,0,0-.108,6.814,28.649,28.649,0,0,0,.645,3.861,47.832,47.832,0,0,0,16.518,2.382,16.045,16.045,0,0,1,2.626-6.95,28.959,28.959,0,0,0-.108,6.814,46.756,46.756,0,0,0,16.24-4.35,17.526,17.526,0,0,0,6.969-5.233,15.563,15.563,0,0,0,2.1-11.252,108.548,108.548,0,0,0-2.917-11.36,21.613,21.613,0,0,1-.38-11.578c1.132-3.746,4.316-7.078,8.218-7.37,1.777-.137,3.563.348,5.341.19s3.706-1.358,3.746-3.145l-1.7-2.767c-.72-4.845-3.19-9.65-7.423-12.113s-10.308-1.942-13.328,1.916c-2.544,3.244-2.45,7.753-3.281,11.788-1.942,9.379-9.434,16.924-18.153,20.9s-18.533,4.8-28.114,4.648a32.7,32.7,0,0,1-9.745-1.275,12.851,12.851,0,0,1-7.552-6Z" transform="translate(-900.795 -348.371)" fill="#2f2e41" />
              <path id="Path_18025" data-name="Path 18025" d="M803.743,443l2.818-33.606a4.355,4.355,0,0,0-3.973-4.7l-7.324-.61a4.355,4.355,0,0,0-4.7,3.973l-2.818,33.606a4.355,4.355,0,0,0,3.977,4.7l7.324.61a4.355,4.355,0,0,0,4.7-3.973Z" transform="translate(-640.555 -366.274)" fill="#3f3d56" />
              <path id="Path_18026" data-name="Path 18026" d="M804.592,407.119a2.352,2.352,0,0,1,2.141,2.533l-2.818,33.606a2.352,2.352,0,0,1-2.533,2.141l-7.324-.61a2.352,2.352,0,0,1-2.141-2.533l2.818-33.606a2.352,2.352,0,0,1,2.533-2.141Z" transform="translate(-643.555 -367.058)" fill="#fbb243" />
              <path id="Path_18027" data-name="Path 18027" d="M806.51,463.4l-7.131-2.72,4.141-15.847.726-7.857a5.857,5.857,0,0,1,9.185-4.263h0a5.857,5.857,0,0,1,1.56,7.991l-4.849,7.473Z" transform="translate(-657.818 -375.142)" fill="#a0616a" />
              <path id="Path_18028" data-name="Path 18028" d="M845.506,462.99l9.507,3.628-4.733,22.22a9.468,9.468,0,0,1-6.167,6.969h0a9.469,9.469,0,0,1-10.691-3.3L812.094,463.08a9.669,9.669,0,0,1,1.8-13.382h0a9.665,9.665,0,0,1,14.164,2.513l13.482,22.6Z" transform="translate(-704.23 -380.284)" fill="#e6e6e6" />
              <path id="Path_18029" data-name="Path 18029" d="M846.493,485.263l-4.806,19.512-1,1.214a7.769,7.769,0,0,1-12.66-.929L812.8,479.737l13.732,19a7.768,7.768,0,0,0,13.125-.853Z" transform="translate(-697.484 -390.595)" opacity="0.2" />
            </g>
          </g>
          <g id="Line">
            <line id="Line_204" data-name="Line 204" y2={174} transform="translate(540.793 5283.329)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
            <line id="Line_224" data-name="Line 224" y2={93} transform="translate(599.793 5522.329)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
            <line id="Line_226" data-name="Line 226" y2={427} transform="translate(666.793 5680.329)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
            <line id="Line_220" data-name="Line 220" y1={651} transform="translate(1265.5 5339.329)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
            <line id="Line_221" data-name="Line 221" x1={222} transform="translate(814.917 5983.329)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} opacity="0.3" />
            <line id="Line_231" data-name="Line 231" x1="164.001" transform="translate(806.916 5803.329)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} opacity="0.3" />
            <line id="Line_234" data-name="Line 234" x1="130.398" transform="translate(726.519 5645.329)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} opacity="0.3" />
            <line id="Line_227" data-name="Line 227" y1={144} transform="translate(1036.917 5839.329)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} opacity="0.3" />
            <line id="Line_230" data-name="Line 230" y1={132} transform="translate(970.466 5671.329)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} opacity="0.3" />
            <line id="Line_232" data-name="Line 232" y1={152} transform="translate(856.917 5493.329)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} opacity="0.3" />
            <line id="Line_228" data-name="Line 228" x1={72} transform="translate(1036.917 5839.329)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} opacity="0.3" />
            <line id="Line_229" data-name="Line 229" x1={142} transform="translate(970.917 5671.329)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} opacity="0.3" />
            <line id="Line_233" data-name="Line 233" x1={252} transform="translate(856.917 5493.329)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} opacity="0.3" />
          </g>
          <g id="Dots">
            <circle id="Ellipse_134" data-name="Ellipse 134" cx="7.5" cy="7.5" r="7.5" transform="translate(534 5280)" fill="#4b9aff" />
            <circle id="Ellipse_1381" data-name="Ellipse 1381" cx="7.5" cy="7.5" r="7.5" transform="translate(592 5530)" fill="#4b9aff" />
            <circle id="Ellipse_1382" data-name="Ellipse 1382" cx="7.5" cy="7.5" r="7.5" transform="translate(660 5680)" fill="#4b9aff" />
            <circle id="Ellipse_1383" data-name="Ellipse 1383" cx="7.5" cy="7.5" r="7.5" transform="translate(659 5840)" fill="#4b9aff" />
            <circle id="Ellipse_1386" data-name="Ellipse 1386" cx="7.5" cy="7.5" r="7.5" transform="translate(659 6023)" fill="#4b9aff" />
            <circle id="Ellipse_1373" data-name="Ellipse 1373" cx="7.5" cy="7.5" r="7.5" transform="translate(1258 5328.095)" fill="#ff6464" />
            <circle id="Ellipse_1384" data-name="Ellipse 1384" cx="7.5" cy="7.5" r="7.5" transform="translate(1258 5536.095)" fill="#ff6464" />
            <circle id="Ellipse_1385" data-name="Ellipse 1385" cx="7.5" cy="7.5" r="7.5" transform="translate(1258 5715.095)" fill="#ff6464" />
            <circle id="Ellipse_1387" data-name="Ellipse 1387" cx="7.5" cy="7.5" r="7.5" transform="translate(1258 5878.095)" fill="#ff6464" />
          </g>
          <g id="Text">
            <g id="Admin" transform="translate(-318.776 -6507.716)">
              <rect id="Rectangle_298" data-name="Rectangle 298" width={139} height={43} rx="21.5" transform="translate(1404.776 11863.812)" />
              <text id="Admin-2" data-name="Admin" transform="translate(1474.776 11889.812)" fill="#fff" fontSize={14} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}><tspan x="-22.561" y={0}>Admin</tspan></text>
            </g>
            <g id="User" transform="translate(-704.685 -6511.235)">
              <rect id="Rectangle_298-2" data-name="Rectangle 298" width={141} height={43} rx="21.5" transform="translate(1282 11867)" />
              <text id="User-2" data-name="User" transform="translate(1353 11893)" fill="#fff" fontSize={14} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}><tspan x="-15.414" y={0}>User</tspan></text>
            </g>
            <g id="_2fa" data-name="2fa" transform="translate(26 -93)">
              <g id="Rectangle_45" data-name="Rectangle 45" transform="translate(401.417 5551.829)" fill="#fff" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
                <rect width="352.389" height={62} rx={25} stroke="none" />
                <rect x={1} y={1} width="350.389" height={60} rx={24} fill="none" />
              </g>
              <text id="Connect_WEB3_Wallet" data-name="Connect WEB3 Wallet" transform="translate(577.417 5587.829)" fill="#383838" fontSize={16} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700} letterSpacing="0.03em"><tspan x="-88.776" y={0}>Connect WEB3 Wallet</tspan></text>
            </g>
            <g id="_2fa-2" data-name="2fa">
              <g id="Rectangle_8830" data-name="Rectangle 8830" transform="translate(1108.417 5463.829)" fill="#fff" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
                <rect width="308.389" height={62} rx={25} stroke="none" />
                <rect x={1} y={1} width="306.389" height={60} rx={24} fill="none" />
              </g>
              <text id="Manage_Swap_Pairs" data-name="Manage Swap Pairs" transform="translate(1262.417 5499.829)" fill="#383838" fontSize={16} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700} letterSpacing="0.03em"><tspan x="-79.56" y={0}>Manage Swap Pairs</tspan></text>
            </g>
            <g id="KYC" transform="translate(-96 4717.829)">
              <g id="Rectangle_45-2" data-name="Rectangle 45" transform="translate(572.417 897)" fill="#fff" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
                <rect width={253} height={62} rx={25} stroke="none" />
                <rect x={1} y={1} width={251} height={60} rx={24} fill="none" />
              </g>
              <text id="Choose_Pairs_to_Swap" data-name="Choose Pairs to Swap" transform="translate(698.417 933)" fill="#383838" fontSize={14} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}><tspan x="-72.849" y={0}>Choose Pairs to Swap</tspan></text>
            </g>
            <g id="User_Dashboard" data-name="User Dashboard" transform="translate(32.194 5007.194)">
              <g id="Rectangle_45-3" data-name="Rectangle 45" transform="translate(501.223 946.635)" fill="#fff" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
                <rect width={285} height={62} rx={25} stroke="none" />
                <rect x={1} y={1} width={283} height={60} rx={24} fill="none" />
              </g>
              <text id="Stake_LP_Tokens" data-name="Stake LP Tokens" transform="translate(643.223 982.635)" fill="#383838" fontSize={14} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}><tspan x="-54.012" y={0}>Stake LP Tokens</tspan></text>
            </g>
            <g id="User_Dashboard-2" data-name="User Dashboard" transform="translate(32.194 5163.194)">
              <g id="Rectangle_45-4" data-name="Rectangle 45" transform="translate(501.223 946.635)" fill="#fff" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
                <rect width={285} height={62} rx={25} stroke="none" />
                <rect x={1} y={1} width={283} height={60} rx={24} fill="none" />
              </g>
              <text id="Harvest_Your_Rewards" data-name="Harvest Your Rewards" transform="translate(643.223 982.635)" fill="#383838" fontSize={14} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}><tspan x="-74.27" y={0}>Harvest Your Rewards</tspan></text>
            </g>
            <g id="My_Profile" data-name="My Profile" transform="translate(32.194 4925.194)">
              <g id="Rectangle_45-5" data-name="Rectangle 45" transform="translate(439.223 844.571)" fill="#fff" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
                <rect width={401} height={62} rx={25} stroke="none" />
                <rect x={1} y={1} width={399} height={60} rx={24} fill="none" />
              </g>
              <text id="Provide_Liquidity_to_get_LP_Tokens" data-name="Provide Liquidity to get LP Tokens" transform="translate(639.223 880.571)" fill="#383838" fontSize={14} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}><tspan x="-114.842" y={0}>Provide Liquidity to get LP Tokens</tspan></text>
            </g>
            <g id="Website_Content" data-name="Website Content" transform="translate(611 4880.095)">
              <g id="Rectangle_45-6" data-name="Rectangle 45" transform="translate(503.417 759.734)" fill="#fff" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
                <rect width={301} height={62} rx={20} stroke="none" />
                <rect x={1} y={1} width={299} height={60} rx={19} fill="none" />
              </g>
              <text id="Add_Remove_Liquidity_Pairs" data-name="Add/Remove Liquidity Pairs" transform="translate(654.417 795.734)" fill="#383838" fontSize={14} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}><tspan x="-94.717" y={0}>Add/Remove Liquidity Pairs</tspan></text>
            </g>
            <g id="Website_Content-2" data-name="Website Content" transform="translate(611 5042.095)">
              <g id="Rectangle_45-7" data-name="Rectangle 45" transform="translate(503.417 766.734)" fill="#fff" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
                <rect width={301} height={62} rx={20} stroke="none" />
                <rect x={1} y={1} width={299} height={60} rx={19} fill="none" />
              </g>
              <text id="Staking_Management" data-name="Staking Management" transform="translate(654.417 802.734)" fill="#383838" fontSize={14} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}><tspan x="-72.821" y={0}>Staking Management</tspan></text>
            </g>
            <g id="Website_Content-3" data-name="Website Content" transform="translate(611 5218.095)">
              <g id="Rectangle_45-8" data-name="Rectangle 45" transform="translate(503.417 766.734)" fill="#fff" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
                <rect width={301} height={62} rx={20} stroke="none" />
                <rect x={1} y={1} width={299} height={60} rx={19} fill="none" />
              </g>
              <text id="Admin_Profit_Settings" data-name="Admin Profit Settings" transform="translate(654.417 802.734)" fill="#383838" fontSize={14} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}><tspan x="-72.212" y={0}>Admin Profit Settings</tspan></text>
            </g>
          </g>
        </g>
      </svg>
      </div>
    </section>
  )
}

export default HowOur