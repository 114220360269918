import * as React from "react"

import Layout from "../components/layout"
import { Helmet } from "react-helmet"
import BannerSection from "../components/defidevelopment/Banner"
import Whatis from "../components/defidevelopment/Whatis"
import WhyShould from "../components/defidevelopment/WhyShould"
import TopFeatures from "../components/defidevelopment/TopFeatures"
import HowOur from "../components/defidevelopment/HowOur"
import CoreFeatures from "../components/defidevelopment/CoreFeatures"
import SecurityofOur from "../components/defidevelopment/SecurityofOur"
import UsecaseOf from "../components/defidevelopment/UsecaseOf"
import WhyChoose from "../components/defidevelopment/WhyChoose"
import Testimonial from "../components/whitelabelpage/Testimonial"
import Review from "../components/Landing/Review"
import PortfolioSection from "../components/whitelabelpage/Portfolio"
import DevApproach from "../components/defidevelopment/DevApproach"
import TechStack from "../components/defidevelopment/TechStack"
import FaqSection from "../components/defidevelopment/FaqSection"
import UserFriendly from "../components/Cryptoexchange/UserFriendly"
import BenifitsOf from "../components/defidevelopment/BenifitsOf"
import WeProvide from "../components/defidevelopment/WeProvide"

// import Header from "../components/header"




const DeFiDevelopment= () => (
  <section className="dev-page">
    <Layout>
      <Helmet>
        <meta name="robots" content="index, follow" />
        <title>DeFi Development Company | DeFi Development Services</title>
        <meta name="description" content="Coinsclone is a leading DeFi development company. We provide best DeFi Development Services like dApps, DeFi Lending, Smart Contract, Etc," />
        <meta name="keywords" content="DeFi Development Services, Decentralized Finance Development Company, Launch your own DeFi Exchange Platform, DeFi Exchange Development Services" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="DeFi Development Company | DeFi Development Services" />
        <meta property="og:description" content="Coinsclone is a leading DeFi development company. We provide best DeFi Development Services like dApps, DeFi Lending, Smart Contract, Etc," />
        <meta property="og:url" content="https://www.coinsclone.com/defi-development-company/" />
        <meta property="og:image" content="https://coinsclone.mo.cloudinary.net/images/defi-development-company.png" />
        <meta property="og:site_name" content="Coinsclone" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@Coinsclone" />
        <meta name="twitter:creator" content="@Coinsclone" />
        <meta name="twitter:description" content="Coinsclone is a leading DeFi development company. We provide best DeFi Development Services like dApps, DeFi Lending, Smart Contract, Etc," />
        <meta name="twitter:title" content="DeFi Development Company | DeFi Development Services" />
        <meta name="twitter:image" content="https://coinsclone.mo.cloudinary.net/images/defi-development-company.png" />
        <link rel="canonical" href="https://www.coinsclone.com/defi-development-company/" />
      </Helmet>
      {/* <Header /> */}
      <BannerSection />
      <div className="breadcrumb mb-0">
        <div className="container">
          <span><a href="https://www.coinsclone.com/">Home</a> | DeFi Development Company</span>
        </div>
      </div>
      <Whatis />
      <WhyShould />
      <TopFeatures />
      <HowOur />
      <BenifitsOf />
      <CoreFeatures />
      <SecurityofOur />
      <UsecaseOf />
      <WeProvide />
      <DevApproach />
      <Testimonial />
      <Review />
      <PortfolioSection />
      <WhyChoose />
      <TechStack />
      <FaqSection />
      <UserFriendly />
      
    </Layout>
  </section>
)

export default DeFiDevelopment
