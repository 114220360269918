import React from "react"



class WeProvide extends React.Component {

  render() {
    return (

      <section className="usr-admin-tab benifor defidev mb-0 pt-100">
          <div className="container"> 
            <h2 className="heading-h2 text-center">DeFi Development on <span className="bluecolor">various Blockchain</span> Network</h2>
            <p className="text-center">
            We offer infinite Defi development services on popular blockchain networks for newbie startups and entrepreneurs.
            </p>
            <div className="row">
                  <div className="d-lg-flex flex-wrap add-one">
                    <div className="square" >
                      <h3>Ethereum</h3>
                      <p className="pharagraph">
                      As the foremost blockchain network in the crypto universe, we create DeFi platforms in the Ethereum network. This blockchain is compatible with various other blockchains and highly secure.
                      </p>
                    </div>
                    <div className="square" >
                      <h3>Solana</h3>
                      <p className="pharagraph">
                      Our DeFi development services also support the Solana blockchain which has higher speed, and reduced costs. The Solana blockchain network eliminates the congestion and scalability issues.
                      </p>
                    </div>
                    <div className="square" >
                      <h3>Cardano</h3>
                      <p className="pharagraph">
                      The Cardano blockchain network highly focuses on providing seamless features and functionalities to the DeFi platforms. Startups aim to create lending and borrowing platforms for crypto lenders.
                      </p>
                    </div>
                    <div className="square mb-0" >
                      <h3>Polkadot</h3>
                      <p className="pharagraph">
                      Polkadot is an advanced-level blockchain network that facilitates new concepts like the decentralized Sovereign Wealth Fund (dSWF). This blockchain is highly known for its liquidity staking solutions.
                      </p>
                    </div>
                    <div className="square mb-0" >
                      <h3>Avalanche</h3>
                      <p className="pharagraph">
                      We offer DeFi development services on the Avalanche blockchain for projects that expect lower costs, higher efficiency, and user-friendliness. We create DeFi platforms on the avalanche blockchain with a unique architecture.
                      </p>
                    </div>
                    <div className="square mb-0" >
                      <h3>Polygon</h3>
                      <p className="pharagraph">
                      The Polygon blockchain arrives as a major network in the crypto cosmos with its utmost features. Our technical team helps you to build seamless DeFi platforms with the polygon network for its affordable transaction cost.
                      </p>
                    </div>
                  </div>
            </div>
          </div>
      </section>
    )
  }
}

export default WeProvide
